import React from 'react'
import style from './styles'
import spinner from '../../assets/spinner.gif'

const useButtonLoader = ({ loading, text }) => (
    <button disabled={loading} style={style.button}>
        {loading ? <span className="oneSpan"><img style={{ width: '30px' }} alt={spinner} src={spinner} /></span> : <span className="twoSpan">{text}</span>}
    </button>
)

export default useButtonLoader;