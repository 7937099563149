import React from 'react'
import { home } from '../../assets/screens'
import styles from './styles'

const HomeScreen = () => (
    <div style={styles.container}>
        <img src={home} style={styles.img} />
    </div>
)

export default HomeScreen