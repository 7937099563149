const styles = {
  
    button: {
        backgroundColor: '#4545c7',
        borderRadius: '50px',
        border: '1px',
        display: 'inline-block',
        cursor:'pointer',
        color:'#ffffff',
        fontFamily:'sans-serif',
        fontSize:'12px',
        fontWeight:'bold',
        padding:'17px 11px',
        width: '6vw',
      },

  
  }

export default styles