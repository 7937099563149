import React from 'react'
import instructive from '../../assets/screens/instructive.jpg'
import styles from './styles'

const InstructiveScreen = () => (
    <div style={styles.container}>
        <img src={instructive} style={styles.img} />

    </div>
)

export default InstructiveScreen