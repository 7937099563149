import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import axios from 'axios'
import style from './styles'
import ButtonLoading from '../../components/button/useButton'
import Toast from '../../components/alert/useAlert'


const VerifyCodeScreen = ({ phonenumber, clientfemsa }) => {
    const [alert, setAlert] = useState({ title: '', show: false, message: '' });
    const [loading, setLoading] = useState(false);

    const { register, errors, handleSubmit } = useForm({
        reValidateMode: 'onSubmit'
    })

    const onSubmit = (data, e) => {
        setLoading(true)
        const { code } = data

        axios.put(`https://us-central1-cuponera-coca-cola-uruguay.cloudfunctions.net/app/punto-venta/update-phone/${clientfemsa}/${code}/${phonenumber}`)
            .then(() => {
                setLoading(false)
                setAlert({ title: 'Confirmado!', show: true, message: 'Número telefónico actualizado con éxito.' })
            })
            .catch(error => {
                setLoading(false)
                setAlert({ title: 'Error', show: true, message: 'El código es erróneo' })
                e.target.reset()
            })
    }

    return (
        <div style={style.container}>
            <div style={style.card}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                        style={style.codigo}
                        name="code"
                        placeholder='Codigo'
                        type='number'
                        ref={
                            register({
                                required: { value: true, message: 'Campo Obligatorio' },
                                valueAsNumber: { value: true }
                            })
                        }
                    />
                    <ErrorMessage errors={errors} name="code" render={({ message }) => <p style={style.error}>{message}</p>} />

                    <div style={style.divButton}>
                        <ButtonLoading loading={loading} text='Enviar' />
                    </div>
                </form>
            </div>

            {alert.show && (<Toast title={alert.title} message={alert.message} timer={() => setAlert(false)} />)}

        </div>
    )
}

export default VerifyCodeScreen