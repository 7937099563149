import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useHistory } from 'react-router-dom'
import ButtonLoading from '../../components/button/useButton'
import Toast from '../../components/alert/useAlert'
import axios from 'axios'
import style from './styles'

const ClientScreen = () => {

    const history = useHistory()
    const [showAlert, setAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const { register, errors, handleSubmit } = useForm({
        reValidateMode: 'onSubmit'
    })

    const onSubmit = (data, e) => {
        setLoading(true)

        const { clientfemsa, areanumber, number } = data
        const phoneNumber = `5989${areanumber}${number}`

        axios.post(`https://us-central1-cuponera-coca-cola-uruguay.cloudfunctions.net/app/punto-venta/send-phone-code/${clientfemsa}/${phoneNumber}`)
            .then((res) => history.push(`/verifycode/${clientfemsa}/${phoneNumber}`))
            .catch(error => {
                setLoading(false)
                setAlert(true)
                e.target.reset()
            })
    }

    return (
        <div style={style.container}>
            <form style={style.card} onSubmit={handleSubmit(onSubmit)}>
                <input
                    style={style.clientFemsa}
                    name="clientfemsa"
                    placeholder='Cliente FEMSA'
                    type='number'
                    ref={
                        register({
                            required: { value: true, message: 'Campo Obligatorio' },
                            valueAsNumber: { value: true }
                        })
                    }
                />
                <ErrorMessage errors={errors} name="clientfemsa" render={({ message }) => <p style={style.error}>{message}</p>} />

                <div style={{ display: 'flex', flexDirection: 'row' }}>

                    <input
                        style={style.areaNumber}
                        name="prefix"
                        defaultValue="5989"
                        placeholder='Prefijo'
                        type='text'
                        ref={
                            register({
                                required: { value: true, message: 'Campo Obligatorio' },
                                valueAsNumber: true
                            })
                        }
                    />

                    <input
                        style={style.areaNumber}
                        name="areanumber"
                        placeholder='Codigo de Área'
                        type='number'
                        ref={
                            register({
                                required: { value: true, message: 'Campo Obligatorio' },
                                valueAsNumber: true
                            })
                        }
                    />

                    <input
                        style={style.phoneNumber}
                        name="number"
                        placeholder='Número de telefono'
                        type='number'
                        ref={
                            register({
                                required: { value: true, message: 'Campo Obligatorio' },
                                valueAsNumber: true
                            })
                        }
                    />
                    <ErrorMessage errors={errors} name="number" render={({ message }) => <p style={style.error}>{message}</p>} />
                </div>

                <div style={style.divButton}>
                    <ButtonLoading loading={loading} text='Enviar Código' />
                    {showAlert && (<Toast title='Error' message='El n° de cliente femsa ingresado no existe' timer={() => setAlert(false)} />)}
                </div>

            </form>
        </div>
    )
}

export default ClientScreen