import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { HomeScreen, ClientScreen, InstructiveScreen, VerifyCodeScreen } from '../features'

export default function App() {
    return (
        <Router>
            <Switch>

                <Route path="/client" component={ClientScreen} />

                <Route path="/verifycode/:clientfemsa/:phonenumber" render={({match})=>{
                return(
                    <VerifyCodeScreen phonenumber={match.params.phonenumber} clientfemsa={match.params.clientfemsa}/>)}} />

                <Route path="/instructivo" component={InstructiveScreen} />

                <Route exact path="/"  component={HomeScreen} />

            </Switch>
        </Router>
    );
}
