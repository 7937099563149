const styles = {

  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    height: '100vh'
  },

  card: {
    display: 'flex',
    flex: 0.4,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'rgb(250, 250, 250)',
    borderRadius: '10px',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    padding: '30px'
  },

  codigo: {
    display: 'inline',
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid white',
    padding: '10px 15px',
    marginBottom: '10px',
    fontSize: '14px',
    fontFamily: 'sans-serif'
  },

  divButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  error: {
    fontFamily: 'sans-serif',
    fontSize: 12,
    color: 'red',
  }

}

export default styles