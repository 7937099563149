const styles = {
    container: {
        display: 'flex',
        flex: 1,
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center'
    },
    img: {
        width: '100vw',
    }
}

export default styles